import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import t_hub_logo from '../../assets/auverse.png';

const Products = () => {
  const [products, setProducts] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Check if the token exists
    if (!token) {
      console.log('JWT token not found.');
      return;
    }

    axios.get(process.env.REACT_APP_DEV_API_URL + "/product/", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setProducts(response.data.data);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, []);

  return (
    <Container fluid className="dashboard-layout">
      <Row>
        <Col>
          <img src={t_hub_logo} alt="t_hub_logo" className="logo-style" />
        </Col>
      </Row>
      {products.map((product) => (
        <Row key={product.id}>
          <Col>
            <img
              src={product.productImage}
              alt={product.productName}
              style={{ maxWidth: '200px', maxHeight: '200px', width: '100%', height: 'auto' }}
              className="img-fluid"
            />
            <h2>{product.productName}</h2>
            <p>{product.description}</p>
            <h3>{product.price}</h3>
            <Button onClick={() => history.push(`/buyproduct/?productId=${product.productID}`)}>Buy Now</Button>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Products;
