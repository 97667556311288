import React, { Component } from "react";
import './style.scss';
import DashboardChild from "./dashboard";
import { Container, Col, Row, Button } from 'react-bootstrap';
import t_hub_logo from '../../assets/auverse.png';
import axios from 'axios';
import AvatarCreatorApp from "../AvatarCreater";
import { withRouter } from "react-router-dom";

const SERVER_URL = process.env.REACT_APP_DEV_API_URL + "auth/getUser";
const token = localStorage.getItem('token');
const options = {
  headers: { 'Authorization': 'Bearer ' + token }
};

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: "NA",
      createAvatar: true,
    };
  }

  componentDidMount() {
    // let query = window.location.search;
    // if(!query.includes('show=true')){
    // axios.get(SERVER_URL, options)
    //   .then(res => {
    //     this.setState({ avatar: res.data.data.avatar });
    //     console.log("MODEL IS",res.data.data.avatar.length)
    //     if (res.data.data.avatar && res.data.data.avatar.length> 3 ) {
    //       this.props.history.push('/app');
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     axios.get(SERVER_URL, options)
    //   .then(res => {
    //     this.setState({ avatar: res.data.data.avatar });
    //     if (res.data.data.avatar.length > 3 ) {
    //       alert("You have already created your avatar");
    //     }
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    //   });
    // }
  }


  createNewAvatarHandler = () => {
    this.setState({ createAvatar: true });
  };

  render() {
    return (
      <Container className="dashboard-layout">
          <AvatarCreatorApp />
      </Container>
    );
  }
}

export default withRouter(Dashboard);
