import React, { useState, useEffect } from 'react'; 
import { Form, Button, Card, Alert, Container, Row, Col } from 'react-bootstrap';  
import * as Yup from 'yup';
import axios from 'axios'; 
import { Redirect, useHistory } from 'react-router-dom';
import { FacebookProvider, LoginButton } from "react-facebook";
import { useLogin } from 'react-facebook';
import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';

const SERVER_URL = process.env.REACT_APP_DEV_API_URL + "auth/login";
const CONFIRM_URL = process.env.REACT_APP_DEV_API_URL + "auth//verify-otp";
const RESEND_URL = process.env.REACT_APP_DEV_API_URL + "auth/resend-verify-otp";
const CODE_MAX_VALUE = 9999;
const CODE_MIN_VALUE = 1000; 

const schema = Yup.object().shape({ 
    email : Yup.string().email("Enter Proper Email").required("Email is Required"), 
    password : Yup.string().required("Password is required")
})

const ConfirmForm = () => {
    const history = useHistory();
    const [avatar, setAvatar] = useState('');
    const [orientation, setOrientation] = useState(
        window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait'
    );
    const query = new URLSearchParams(window.location.search);
    const urlEmail = query.get("email");
    const [email, setEmail] = useState(urlEmail ? urlEmail : '');
    const [otp, setOtp] = useState('');
    const [resp, setResp] = useState(null);
    const [status, setStatus] = useState('rejected');
    const [code, setCode] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [userId, setUserId] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [rotate, setRotate ] = useState(false);
  
    const handleWindowResize = () => {
        const isLandscape = window.matchMedia('(orientation: landscape)').matches;
        setOrientation(isLandscape ? 'landscape' : 'portrait');
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const isLandscape = window.matchMedia('(orientation: landscape)').matches;
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const isAndroid = /Android/.test(navigator.userAgent);
        console.log(isLandscape, isIOS, isAndroid)
        if (isLandscape && (isIOS || isAndroid)) {
            setRotate(true);
        }
        else setRotate(false);
    }, [orientation]);
    
    // 
    const onFormSubmit = async (e) => {
        e.preventDefault();
        setValidationError(false);
        setValidationMessage('');
        setErrorOccured(false);
        if (email === '' || otp === '') {
            setValidationError(true);
            setValidationMessage('Email and Password are required');
            return;
        }
        const data = {
            email: email,
            otp: otp
        };
        try {
            const response = await axios.post(CONFIRM_URL, data);
            if (response.status === 200) {
                saveToken(response);
                setStatus('resolved');
            }
            else {
                setValidationError(true);
                setValidationMessage(response.data.message)
            }
        } catch (error) {
            console.log(error.response.data.message)
            setValidationError(true);
            setValidationMessage(error.response.data.message);
        }
    }

    const saveToken = (response) => {   
        console.log(response)
        if (response.data.token)  {  
            localStorage.setItem('token', response.data.token);
            history.push('/avatar')
        }
    } 

    const onResentOTP = async (e) => {
        e.preventDefault();
        setValidationError(false);
        setValidationMessage('');
        setErrorOccured(false);
        if (email === '') {
            setValidationError(true);
            setValidationMessage('Email is required');
            return;
        }
        const data = {
            email: email
        };
        try {
            const response = await axios.post(RESEND_URL, data);
            if (response.status === 200) {
                setResp(response.data);
                setStatus('resolved');
                alert(response.data.message)
                window.location.reload();
            }
        }
        catch (error) {
            setErrorOccured(true);
        }
    }
    
    return (
        <div>
        { !rotate && (
        <div className="row backgroundImageStyle">
            
            <div xs={12} sm={12} md={16} lg={16}>
                <div className="login-form">
                    <h3 className='text-login'>Confirm Account</h3>
                    {/* <p className='text-login-2'>Please enter your login and password</p> */}
                    
                    <Form>
                        {/* <Form.Group controlId="formBasicEmail">
                            <Form.Control  
                                type="hidden"  
                                className="button-login input"
                                placeholder="                  E-mail"  
                                value={email}  
                                onChange={(e) => setEmail(e.target.value)}  
                            /> 
                            <Form.Control.Feedback type="invalid">Enter Proper Email</Form.Control.Feedback>
                        </Form.Group>  */}
                        <input className="button-login" name="code" placeholder='        Username' onChange={(e) => setEmail(e.target.value)} value={email} />
                        <input className="button-login-2" type="text" placeholder='             OTP' name="code" onChange={(e) => setOtp(e.target.value)}  />
                        <Button style={{backgroundImage:"url('/verifyb.png')",backgroundSize:"cover", width:"240px", height:"75px", border:"0px", position: 'relative', top: '-225px', left: '-10px' , backgroundColor: 'transparent'}} className="mr-3 btn btn-primary-outline text-color" onClick={onFormSubmit}></Button>
                        <Button style={{backgroundImage:"url('/resendb.png')",backgroundSize:"cover", width:"240px", height:"75px", border:"0px", position: 'relative', top: '-300px', left: '190px' , backgroundColor: 'transparent'}} className="mr-3 btn btn-primary-outline text-color" onClick={onResentOTP}></Button>
                        
                        {/* <button style={{backgroundImage:"url('/Button.png')",backgroundSize:"cover", width:"200px", height:"40px"}} className="mb-2 btn btn-primary-outline text-color"  onClick={redirectRegister}>
                            Register
                        </button> */}
                    </Form>
                     
                </div>   
                {validationError && 
                    <Alert variant="warning" className="mt-3">  
                        {validationMessage}
                    </Alert>
                }
                {errorOccured &&  
                    <Alert variant="danger" className="mt-3">  
                        Something went wrong! Please try again later.
                    </Alert>
                }
            </div>
        </div>
        )}
                     { rotate && (
                        <div>
                        {/* <p align="center" style={{fontSize:"4em"}}><b>Please Rotate Phone to portrait </b></p> */}
                         <img src="/rotateportrait.jpeg" alt="rotate" style={{width:"100%", height:"100%", alignItems:"left"} }/>
                         </div>
                     )}
       </div>
    );
}

export default ConfirmForm;
