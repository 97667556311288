// ViewOrders.js

import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import "./style.scss";

const ViewOrders = () => {
  const [orders, setProducts] = useState([]);
  const { productId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          console.log('JWT token not found.');
          return;
        }
        // Fetch product details
        const productResponse = await axios.get(`${process.env.REACT_APP_DEV_API_URL}order`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(productResponse.data.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchData();
  }, [productId]);

  return (
    <Container fluid className="dashboard-layout">
      <Suspense fallback={<div>Loading...</div>}>
        {orders.map(order => (
          <Row key={order.id}>
            <Col>
              <div className="product-container"> {/* Added wrapping div */}
                <img
                  src={order.prodDetails[0].productImage}
                  alt={order.prodDetails[0].productName}
                  style={{ maxWidth: '400px', maxHeight: '400px', width: '100%', height: 'auto' }}
                  className="img-fluid"
                />
                <h2>{order.prodDetails[0].productName}</h2>
                <h3> Order Status: {order.status}</h3>
                <p>{order.prodDetails[0].description}</p>
                <h3>{order.price}</h3>
              </div>
            </Col>
          </Row>
        ))}
      </Suspense>
    </Container>
  );
};

export default ViewOrders;
