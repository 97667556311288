import { AvatarCreator } from '@readyplayerme/react-avatar-creator';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Fab } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const config = {
  clearCache: true,
  bodyType: 'fullbody',
  quickStart: false,
  language: 'en',
};
const styleFab = {
  margin: 0,
  top: 'auto',
  right: 'auto',
  bottom: 20,
  left: 20,
  position: 'fixed',
};
const style = { width: window.innerWidth, height: window.innerHeight, border: 'none' };

export default function AvatarCreatorApp() {
  const history = useHistory();
  const [rotate, setRotate ] = useState(false);

  useEffect(() => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);
    console.log(isLandscape, isIOS, isAndroid)
    if (isLandscape && (isIOS || isAndroid)) {
        setRotate(true);
    }
    else setRotate(false);
}, []);

  const handleOnAvatarExported = async (event) => {
    try {
      const avatarURL = event.data.url;

      // Retrieve the JWT token from localStorage (you may need to adjust this based on your implementation)
      const token = localStorage.getItem('token');

      // Check if the token exists
      if (!token) {
        console.log('JWT token not found.');
        return;
      }

      // Make an axios call to update the user profile with the avatarURL
      const response = await axios.post(
        `${process.env.REACT_APP_DEV_API_URL}auth/updateProfile`,
        { avatarURL },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
          },
        }
      );

      console.log('Profile updated:', response.data);
      history.push('/app?location=previous');
      window.location.reload();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };
  const onGoBack = () => {
    history.push('/app?location=previous');
    window.location.reload();
  }
  return (
    <>
    { !rotate && (<>
      <AvatarCreator subdomain="auverse" config={config} style={style} onAvatarExported={handleOnAvatarExported} />
      
    {/* <Fab color="secondary" aria-label="edit" style={styleFab} variant="extended" onClickCapture={onGoBack}>
    <ArrowBackIcon sx={{ mr: 1 }}/> Go Back
  </Fab> */}
  </>
      )}
      { rotate && (
                        <div>
                        {/* <p align="center" style={{fontSize:"4em"}}><b>Please Rotate Phone to portrait </b></p> */}
                         <img src="/rotateportrait.jpeg" alt="rotate" style={{width:"100%", height:"100%", alignItems:"left"} }/>
                         </div>
                     )}

    </>
  );
}
