import React, { Fragment, useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import "./style.scss";

const SERVER_URL = process.env.REACT_APP_DEV_API_URL + "auth/getUser";

export default function Necklace1() {
  const [avatar, setAvatar] = useState("");
  const [orientation, setOrientation] = useState(
    window.matchMedia("(orientation: landscape)").matches
      ? "landscape"
      : "portrait"
  );
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );
  const [rotate, setRotate ] = useState(false);

  useEffect(() => {
    const isLandscape = window.matchMedia('(orientation: landscape)').matches;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);
    console.log(isLandscape, isIOS, isAndroid)
    if (isLandscape && (isIOS || isAndroid)) {
        setRotate(true);
    }
    else setRotate(false);
}, [orientation]);
  const { unityProvider, loadingProgression, isLoaded, sendMessage } =
    useUnityContext({
      loaderUrl: "/necklace1/Build/Build.loader.js",
      dataUrl: "/necklace1/Build/Build.data.unityweb",
      frameworkUrl: "/necklace1/Build/Build.framework.js.unityweb",
      codeUrl: "/necklace1/Build/Build.wasm.unityweb",
      symbolsUrl: "/necklace1/Build/Build.symbols.json.unityweb",
    });

  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const previous = query.get("previous");
    console.log("PREVIOUS", previous);
    const token = localStorage.getItem("token");
    if (loadingProgression >= 0.99 && avatar === "") {
      passJwtToken();
    } else sendMessage("MessageReceiver", "HandleMessageFromMain", `${token},${previous}`);
  }, [loadingProgression, avatar]);

  const passJwtToken = () => {
    const query = new URLSearchParams(window.location.search);
    const previous = query.get("previous");
    const token = localStorage.getItem("token");
    const options = {
      headers: { Authorization: "Bearer " + token },
    };

    axios
      .get(SERVER_URL, options)
      .then((res) => {
        console.log("THIS IS THE AVATAR", res.data.data.avatar);
        setAvatar(res.data.data.avatar);
        sendMessage(
          "MessageReceiver",
          "HandleMessageFromMain",
          `${token},${previous}`
        );
        console.log("AVATAR HAS BEEN SENT TO UNITY");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleWindowResize = () => {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    setOrientation(isLandscape ? "landscape" : "portrait");
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
    { !rotate && (
    <div className="loading">
      <Fragment>
        {!isLoaded && (
          <div className="loading">
          <img src="/loading_portrait.gif" height={window.innerHeight} width={window.innerWidth}/>
            <div className="loading-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "10vh",
                zIndex: 10,
              }}
            >
              <p style={{ color: "white" }}>
               <b> {Math.round(loadingProgression * 100)}  %</b>
              </p>
            </div>
          </div>
        )}
        {/* {window.innerHeight < window.innerWidth && ( */}
          <Unity
            unityProvider={unityProvider}
            style={{
              visibility: isLoaded ? "visible" : "hidden",
              width: window.innerWidth - 1,
              height: window.innerHeight - 1,
              border: "2px solid black",
            }}
            devicePixelRatio={devicePixelRatio}
          />
        {/* )} */}
        {/* {window.innerHeight > window.innerWidth && ( */}
          <img
            src="/rotate.jpeg"
            alt="rotate"
            style={{ width: "100%", height: "auto" }}
          />
        {/* )} */}
      </Fragment>
    </div> )}
    { rotate && (
                        <div>
                        {/* <p align="center" style={{fontSize:"4em"}}><b>Please Rotate Phone to portrait </b></p> */}
                         <img src="/rotateportrait.jpeg" alt="rotate" style={{width:"100%", height:"100%", alignItems:"left"} }/>
                         </div>
                     )}
    </>
  );
}
