import React, { useState, useEffect } from 'react'; 
import { Form, Button, Card, Alert, Container, Row, Col } from 'react-bootstrap';  
import * as Yup from 'yup';
import axios from 'axios'; 
import { Redirect, useHistory } from 'react-router-dom';
import { FacebookProvider, LoginButton } from "react-facebook";
import { useLogin } from 'react-facebook';
import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLoginButton } from "react-social-login-buttons";
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';

const SERVER_URL = process.env.REACT_APP_DEV_API_URL + "auth/register";
const CODE_MAX_VALUE = 9999;
const CODE_MIN_VALUE = 1000; 

const schema = Yup.object().shape({ 
    email : Yup.string().email("Enter Proper Email").required("Email is Required"), 
    password : Yup.string().required("Password is required")
})

const LoginForm = () => {
    const history = useHistory();
    const [avatar, setAvatar] = useState('');
    const [orientation, setOrientation] = useState(
        window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait'
    );
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [resp, setResp] = useState(null);
    const [status, setStatus] = useState('rejected');
    const [code, setCode] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorOccured, setErrorOccured] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [userId, setUserId] = useState(null);
    const [redirect, setRedirect] = useState(false);
  
    const handleWindowResize = () => {
        const isLandscape = window.matchMedia('(orientation: landscape)').matches;
        setOrientation(isLandscape ? 'landscape' : 'portrait');
    };

    useEffect(() => {
        console.log("TERMS AND CONDITIONS", termsAndConditions)
    }, [termsAndConditions]);
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() => {
        const initialValue = document.body.style.zoom;
    
        // Change zoom level on mount
        document.body.style.zoom = "120%";
    
        return () => {
          // Restore default value
          document.body.style.zoom = initialValue;
        };
      }, []);

    const generateSecretCode = (max = CODE_MAX_VALUE, min = CODE_MIN_VALUE) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; 
    }

    const handleSuccess = async (response) => {
        try {
            const result = await axios.post(`${process.env.REACT_APP_DEV_API_URL}auth/facebook-login`, {
                userId: response.authResponse.userID,
                accessToken: response.authResponse.accessToken,
            });
            saveToken(result);
            setRedirect(true);
            setIsLoggedIn(true);
        } catch (error) {
            console.log(error);
        }
    }
    
    const responseGoogle = async (response) => {
        try {
            console.log(response)
            const result = await axios.post(`${process.env.REACT_APP_DEV_API_URL}auth/google-login`, {
                response
            });
            saveToken(result);
            setRedirect(true);
            setIsLoggedIn(true);
        } catch (error) {
            console.log(error);
        }
    }

    function validatePassword(password) {
        // Regular expression to check the criteria
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        
        // Test the password against the regular expression
        return regex.test(password);
    }

    const formValidation = () => {
        schema.isValid({ 
            email,
            password
        }).then((valid) => {  
            if(termsAndConditions){ 
            if(valid) { 
                const response = { 
                    firstName,
                    lastName,
                    email,
                    password,
                    code: generateSecretCode(),
                    status
                }; 
                if(!validatePassword(password)) {
                    setValidationError(true)
                    setValidationMessage("Password must be at least 8 charecters long and contain one special character , one capital , one small ,one number")
                    return false;
                }
                axios.post(`${SERVER_URL}`, response)
                    .then(res => history.push(`/auth/confirm?email=${email}`))
                    .then(() => setRedirect(true))
                    .catch(function (error) {
                        if (error.response) {
                          // The request was made and the server responded with a status code
                          // that falls out of the range of 2xx
                          console.log(error.response.data.data[0]?.msg);
                          console.log(error.response.status);
                          console.log(error.response.headers);

                        setValidationError(true);
                        setValidationMessage(error.response.data.data[0]?.msg);
                        } else if (error.request) {
                          // The request was made but no response was received
                          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                          // http.ClientRequest in node.js
                          console.log(error.request);
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          console.log('Error', error.message);
                        }
                        console.log(error.config);
                      });
                    }
                    else { 
                        setValidationError(true);
                        setValidationMessage('Enter proper email and password');
                    }
            } else { 
                setValidationError(true);
                setValidationMessage('Please Accept Terms and Conditions');
            }
        }).catch((err) => { 
            setValidationError(true);
            setValidationMessage('Enter proper email and password');
            return false;
        });
    }

    const redirectRegister = () => {
        history.push('/auth/register');
    }

    const saveToken = (response) => {   
        console.log(response)
        if (response.data.token)  {  
            localStorage.setItem('token', response.data.token);
            if(response.data.avatar && response.data.avatar?.length > 3) history.push('/app')
            else history.push('/avatar');
        }
        else {
            localStorage.setItem('token', response.data.data.token);
            if(response.data.data.avatar && response.data.data.avatar?.length > 3) history.push('/app')
            else history.push('/avatar');
        }
    }  

    const onFormSubmit = () => {
        console.log("Button Clicked"); 
        console.log(formValidation());
    }

    const onRegisterClick = () => {
        history.push('/auth/register');
    }
    return (
        <div>
        { window.innerHeight < window.innerWidth && (
        <div className="row backgroundImageStyleReg">
            
            <div xs={12} sm={12} md={16} lg={16}>
                <div className="login-form">
                    <h3 className='text-login'>Register</h3>
                    {/* <p className='text-login-2'>Please enter your login and password</p> */}
                    
                    <Form>
                        {/* <Form.Group controlId="formBasicEmail">
                            <Form.Control  
                                type="hidden"  
                                className="button-login input"
                                placeholder="                  E-mail"  
                                value={email}  
                                onChange={(e) => setEmail(e.target.value)}  
                            /> 
                            <Form.Control.Feedback type="invalid">Enter Proper Email</Form.Control.Feedback>
                        </Form.Group>  */}
                        <input className="button-login-4-registerx"  placeholder='                  First Name' name="code" onChange={(e) => setFirstName(e.target.value)}  />
                        <input className="button-login-3-registerx"  placeholder='                  Last Name' name="code" onChange={(e) => setLastName(e.target.value)}  />
                        <input className="button-login-registerx" name="code" placeholder='                  Email ID' onChange={(e) => setEmail(e.target.value)} />
                        <input className="button-login-2-registerx" type="password" placeholder='                  Password' name="code" onChange={(e) => setPassword(e.target.value)}  />
                        <input type='checkbox' checked={termsAndConditions} style={{position: 'relative', top: '-120px', left: '-200px'}}  onChange={(e) => setTermsAndConditions(e.target.value)}/><p style={{position: 'relative', top: '-155px', left: '70px', color: 'white'}}>I agree to the terms and conditions</p>
                        {/* <Button style={{backgroundImage:"url('/loginb.png')",backgroundSize:"cover", width:"250px", height:"75px", border:"0px", position: 'relative', top: '-225px', left: '-10px' , backgroundColor: 'transparent'}} className="mr-3 btn btn-primary-outline text-color" onClick={onFormSubmit}></Button> */}
                        <Button style={{backgroundImage:"url('/register.png')",backgroundSize:"cover", width:"250px", height:"75px", border:"0px", position: 'relative', top: '-170px', left: '0px' , backgroundColor: 'transparent'}} className="mr-3 btn btn-primary-outline text-color" onClick={onFormSubmit}>
                        <Button style={{backgroundSize:"cover", width:"0px", height:"0px", border:"0px", position: 'relative', top: '-225px', left: '-120px' , backgroundColor: 'transparent', visible: false}} className="mr-3 btn btn-primary-outline text-color" onClick={onFormSubmit} type='submit'></Button>
                        </Button>
                        <Row style={{padding:"10px"}}>
                        <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}>
                            <LoginButton
                                scope="email"
                                onError={handleSuccess}
                                className="btn-transparent"
                                onSuccess={handleSuccess}
                                style={{backgroundImage:"url('/fb.png')",backgroundSize:"cover", backgroundColor:"transparent", width:"34px", height:"34px", border:"0px", position: 'relative', top: '-185px', left: "50px" , margin: '10px'}}
                            >
                                <Button style={{backgroundColor:"transparent", width:"34px", height:"34px", border:"0px", position: 'relative', top: '0px'}}/>
                            </LoginButton>
                        </FacebookProvider>
                        {/* Google Login Button */}
                        {/* <Button 
                            style={{backgroundImage:"url('/google.png')",backgroundSize:"cover",backgroundColor:"transparent", width:"70px", height:"50px"}}
                        /> */}
                        <div style={{position:'relative', top:'-175px' , left:'50px'}}>
                        <GoogleOAuthProvider clientId="675111421707-308kr801bodnkftp5d862dv0ipdc42k9.apps.googleusercontent.com">
                            {true &&  
                                <GoogleLogin 
                                    id="googleLogin"
                                    type='icon'
                                    shape='pill'
                                    theme='dark'
                                    onSuccess={credentialResponse => {
                                        responseGoogle(credentialResponse);
                                    }}
                                    onError={() => {
                                        console.log('Login Failed');
                                    }}
                                    style={{backgroundColor:"transparent", width:"70px", height:"50px", border:"0px", position: 'relative', top: '-215px' , margin: '10px'}}
                                />
                            }    
                        </GoogleOAuthProvider>
                        </div>
                    </Row>
                        {/* <button style={{backgroundImage:"url('/Button.png')",backgroundSize:"cover", width:"200px", height:"40px"}} className="mb-2 btn btn-primary-outline text-color"  onClick={redirectRegister}>
                            Register
                        </button> */}
                    </Form>
                </div>   
                {validationError && 
                    <Alert variant="warning" className="mt-3 warning-message">  
                        {validationMessage}
                    </Alert>
                }
                {errorOccured &&  
                    <Alert variant="danger" className="mt-3 warning-message">  
                        Something went wrong! Please try again later.
                    </Alert>
                }
            </div>
        </div>
        )}
                     {window.innerHeight > window.innerWidth && (
                        
                         <img src="/rotate.jpeg" alt="rotate" style={{width:"100%", height:"100%"}}/>
                     )}
       </div>
    );
}

export default LoginForm;
