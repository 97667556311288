import React, { useEffect, useState } from 'react';
import UnityHandler from '../UnityHandler';
import Necklace1 from '../Necklace1';
import Necklace2 from '../Necklace2';
import BuyProduct from '../ViewCart';
import Basic from '../basic';
import MidStore from '../midStore';
import PremiumStore from '../premiumStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { requestForToken, onMessageListener } from '../../firebase';
import Axios from 'axios';
import Shop from '../Shop';
import AR from '../AR';
import AvatarCreatorApp from '../AvatarCreater';

const MainApp = () => {
    const history = useHistory();
    const [app, setApp] = useState("mainApp");
    const [previous, setPrevious] = useState("mainApp");
    const [key, setKey] = useState(0); // Key to force remounting of components
    const [notification, setNotification] = useState({title: '', body: ''});
    useEffect(() => {
      if (notification?.title ){
       alert(notification.title + '\n' + notification.body)
      }
    }, [notification])
    requestForToken(process.env.REACT_APP_DEV_API_URL);
    onMessageListener()
      .then((payload) => {
        setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
      })
      .catch((err) => console.log('failed: ', err));
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const location = query.get("location");
        const storeName = query.get("storeName");
        const modelName = query.get("modelUrl");
        if(location === "previous") {
            if(localStorage.getItem('previous')) history.push(`?location=${localStorage.getItem('previous')}&previous=${location}&storeName=${localStorage.getItem('storeName').replace(" ", "%20")}`);
            else history.push(``);
            window.location.reload();
        }
        else if(location) setApp(`${location}`);
        if(storeName!=null && storeName!='null') localStorage.setItem('storeName', storeName);
        if(modelName) localStorage.setItem('modelName', modelName);
        
        
        const receiveMessage = (message) => {
            console.log("RECEIVED FROM UNITY", message);
            let scenes = message.split(",");

            console.log("THE SCENES ARE",scenes)
            console.log("APP VALUE IS",app);
            // if(scenes[1]) localStorage.setItem('previous', scenes[1]);
            if(scenes[0] === "logout"){
                localStorage.clear();
                history.push(``);
                window.location.reload();
            }
            if(scenes[0] !== "back" && scenes[0]!== "cart"){
                 if(scenes[1] && !scenes[1].includes("http"))
                 {
                    localStorage.setItem('previous', `${location}`);
                    let storeName = scenes[1].replace(" ", "%20");
                    history.push(`?location=${scenes[0]}&previous=${location}&storeName=${storeName}`);
                }
                else {
                    localStorage.setItem('previous', `${location}`);
                    history.push(`?location=${scenes[0]}&previous=${location}&modelUrl=${scenes[1]}`);
                }
            }
            else if(scenes[0] === "cart" || scenes[0] === "shop") 
            {
                localStorage.setItem('previous', `${location}`);
                localStorage.setItem('storeName', storeName != 'null' ? storeName : localStorage.getItem('storeName'));
                history.push(`?location=${scenes[0]}&previous=${location}`);
            }
            else {
                console.log("SETTING SCENE TO ", localStorage.getItem('previous'));
                history.push(`?location=${localStorage.getItem('previous')}&previous=${location}&storeName=${localStorage.getItem('storeName')}`);
            }
            console.log("SCENES", scenes);
            console.log("APP", app);
            window.location.reload();
        };

        window.ReceiveMessage = receiveMessage;

        return () => {
            delete window.ReceiveMessage;
        };
    }, [key]); // Include 'key' in the dependency array to trigger effect on key change

    // Function to force remounting of components
    const reloadComponent = () => {
        setKey(prevKey => prevKey + 1);
    };

    return ( 
        <div>
            { app === "mainApp" && <UnityHandler key={key} reloadComponent={reloadComponent}/> }
            { app === "app" && <UnityHandler key={key} reloadComponent={reloadComponent}/> }
            { app === "main" && <UnityHandler key={key} reloadComponent={reloadComponent}/> }
            { app === "null" && <UnityHandler key={key} reloadComponent={reloadComponent}/> }
            { app === "necklace1" && <Necklace1 key={key} reloadComponent={reloadComponent}/> }
            { app === "necklace2" && <Necklace2 key={key} reloadComponent={reloadComponent}/> }
            { app === "cart" && <BuyProduct key={key} reloadComponent={reloadComponent}/> }
            { app === "shop" && <Shop key={key} reloadComponent={reloadComponent}/> }
            { app === "Basic" && <Basic key={key} reloadComponent={reloadComponent}/> }
            { app === "basic" && <Basic key={key} reloadComponent={reloadComponent}/> }
            { app === "Middle" && <MidStore key={key} reloadComponent={reloadComponent}/> }
            { app === "middle" && <MidStore key={key} reloadComponent={reloadComponent}/> }
            { app === "Premium" && <PremiumStore key={key} reloadComponent={reloadComponent}/> }
            { app === "premium" && <PremiumStore key={key} reloadComponent={reloadComponent}/> }
            { app === "ar" && <AR key={key} reloadComponent={reloadComponent}/> }
            { app === "avatar" && <AvatarCreatorApp key={key} reloadComponent={reloadComponent}/>}
        </div>
    );
};

export default MainApp;
