import React, { Component, Fragment } from 'react';
import ARDesktop from './ArDesktop';
import ARMob from './ArMob';
export class AR extends Component {  

    constructor(props) {
        super(props);
        this.state = {
            isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
            isAndroid: /Android/.test(navigator.userAgent),
            isHorizontal: window.orientation === 90 || window.orientation === -90
        };
    }

    componentDidMount() {
        window.addEventListener('orientationchange', this.handleOrientationChange);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.handleOrientationChange);
    }

    handleOrientationChange = () => {
        this.setState({
            isHorizontal: window.orientation === 90 || window.orientation === -90
        });
    }

    render() {
        const { isIOS, isAndroid, isHorizontal } = this.state;

        if ((isIOS || isAndroid) && isHorizontal) {
            return (
                <div>
                    <img src="/rotateportrait.jpeg" alt="rotate" style={{width:"100%", height:"100%", alignItems:"left"} }/>
                </div>
            );
        } else if (isIOS || isAndroid) {
            return (
                <ARMob />
            );
        } else {
            return (
                <ARDesktop />
            );
        }
    }
}

export default AR;
