import React , {useState, useEffect} from 'react'; 
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'; 
import { Login } from './components/auth/login'
import MainApp from './components/MainApp';  
import 'bootstrap/dist/css/bootstrap.min.css';   
import { urls } from './helpers/routes/urls';
import './App.css'; 
import Dashboard from './components/Dashboard';
import { Register } from './components/register';
import BuyProduct from './components/buyProduct';
import Products from './components/Products';
import ViewOrders from './components/orders';
import Necklace1 from './components/Necklace1';
import Necklace2 from './components/Necklace2';
import BuyProduct2 from './components/ViewCart';
import BuyProductFromLink from './components/buyProductFromLink';
import ConfirmAccount from './components/confirmAccount';
import ForgotPasswordIndex from './components/forgotPassword';
function App() {

  return ( 
    <Router>   
      <Switch>
        <Route path={urls.LOGIN_URL}> 
            <Login/>
        </Route> 
        <Route path={urls.CONFIRM_URL}>
          <ConfirmAccount/>
          </Route>
        <Route path={urls.VISITOR_URL}>
              <MainApp/>
        </Route> 
        <Route path={urls.REGISTER}>
              <Register/>
        </Route>
        <Route path={urls.FORGOTPASSWORD}>
              <ForgotPasswordIndex/>
        </Route>
        <Route  
              exact  
              path={urls.DASHBOARD}  
              render={props => 
              localStorage.getItem("token") 
              ? 
              (<Dashboard/>) 
              :  
              (
              <Redirect to={{pathname:"/"}} /> 
              )
              }
        />
        <Route  
              exact  
              path={urls.NECKLACE1}  
              render={props => 
              localStorage.getItem("token") 
              ? 
              (<Necklace1/>) 
              :  
              (
              <Redirect to={{pathname:"/"}} /> 
              )
              }
        />
        <Route  
              exact  
              path={urls.NECKLACE2}  
              render={props => 
              localStorage.getItem("token") 
              ? 
              (<Necklace2/>) 
              :  
              (
              <Redirect to={{pathname:"/"}} /> 
              )
              }
        />
        <Route
          path={urls.PRODUCTS}
          render={props => 
            localStorage.getItem("token") 
            ? 
            (<Products/>) 
            :  
            (
            <Redirect to={{pathname:"/"}} /> 
            )
            }
        />
        <Route
        path={urls.cart}
        render={props => 
          localStorage.getItem("token") 
          ? 
          (<BuyProduct2/>) 
          :  
          (
          <Redirect to={{pathname:"/"}} /> 
          )
          }
      />
        <Route
          path={urls.APP}
          render={props => 
            localStorage.getItem("token") 
            ? 
            (<MainApp/>) 
            :  
            (
            <Redirect to={{pathname:"/"}} /> 
            )
            }
        />
        <Route
          path={`${urls.BUYPRODUCTWP}/:productLink`}
          render={props => 
            localStorage.getItem("token") 
            ? 
            (<BuyProductFromLink/>) 
            :  
            (
            <Redirect to={{pathname:"/"}} /> 
            )
            }
        />
        <Route
          path={`${urls.BUYPRODUCT}/:productId`}
          render={props => 
            localStorage.getItem("token") 
            ? 
            (<BuyProduct/>) 
            :  
            (
            <Redirect to={{pathname:"/"}} /> 
            )
            }
        />
        <Route
          path={urls.ORDERS}
          render={props => 
            localStorage.getItem("token") 
            ? 
            (<ViewOrders/>) 
            :  
            (
            <Redirect to={{pathname:"/"}} /> 
            )
            }
            ></Route>
        <Route  
            path="/">
            <Redirect to={{pathname:"/auth/login"}} />
        </Route>
        
      </Switch>
    </Router> 
  );
}

export default App;
