import React, { Fragment, useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import "./style.scss";

const SERVER_URL = process.env.REACT_APP_DEV_API_URL + "auth/getUser";

export default function PremiumStore() {
  const [avatar, setAvatar] = useState("");
  const [orientation, setOrientation] = useState(
    window.matchMedia("(orientation: landscape)").matches
      ? "landscape"
      : "portrait"
  );
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const { unityProvider, loadingProgression, isLoaded, sendMessage } =
    useUnityContext({
      loaderUrl: "/premium/Build/Build.loader.js",
      dataUrl: "/premium/Build/Build.data.unityweb",
      frameworkUrl: "/premium/Build/Build.framework.js.unityweb",
      codeUrl: "/premium/Build/Build.wasm.unityweb",
      symbolsUrl: "/premium/Build/Build.symbols.json.unityweb",
    });

  useEffect(
    function () {
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const storeName = query.get("storeName");
    storeName.replace(/%20/g, " ");
    const token = localStorage.getItem("token");
    if (loadingProgression >= 0.99 && avatar === "") {
      passJwtToken();
    } else sendMessage("MessageReceiver", "HandleMessageFromMain", `${token},${avatar},${storeName}`);
  }, [loadingProgression, avatar]);

  const passJwtToken = () => {
    const token = localStorage.getItem("token");
    const options = {
      headers: { Authorization: "Bearer " + token },
    };

    axios
      .get(SERVER_URL, options)
      .then((res) => {
        console.log("THIS IS THE AVATAR", res.data.data.avatar);
        setAvatar(res.data.data.avatar);
        // sendMessage(
        //   "MessageReceiver",
        //   "HandleMessageFromMain",
        //   `${res.data.data.avatar},${token}`
        // );
        console.log("AVATAR HAS BEEN SENT TO UNITY");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleWindowResize = () => {
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    setOrientation(isLandscape ? "landscape" : "portrait");
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="loading">
      {window.innerHeight < window.innerWidth && (
        <Fragment>
          {!isLoaded && (
            <div className="loading">
              <img
                src="loading.gif"
                height={window.innerHeight}
                width={window.innerWidth}
              />
              <div
                className="loading-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                  zIndex: 10,
                }}
              >
                <p style={{ color: "white" }}>
                  <b> {Math.round(loadingProgression * 100)}  %</b>
                </p>
              </div>
            </div>
          )}
          <Unity
            unityProvider={unityProvider}
            style={{
              visibility: isLoaded ? "visible" : "hidden",
              width: window.innerWidth - 1,
              height: window.innerHeight - 1,
              border: "2px solid black",
            }}
            devicePixelRatio={devicePixelRatio}
          />
        </Fragment>
      )}
      {window.innerHeight > window.innerWidth && (
        <img
          src="/rotate.jpeg"
          alt="rotate"
          style={{ width: "100%", height: "auto" }}
        />
      )}
    </div>
  );
}

