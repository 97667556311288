// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { config } from "dotenv"; 
import axios from "axios";
config();

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDetKTSw55hL5Er0WzGOmrRRADDw57vmVQ",
  authDomain: "aumvers.firebaseapp.com",
  projectId: "aumvers",
  storageBucket: "aumvers.appspot.com",
  messagingSenderId: "675599989126",
  appId: "1:675599989126:web:e5a740a8ada460808c5407",
  measurementId: "G-42V4L41R63"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
const analytics = getAnalytics(app);
export const requestForToken = (url) => {
    return getToken(messaging, { vapidKey: "BK7T5mWIz4dqIe6NIPzoCpLuYTXTak2Q353XNCyjD-QUjfmdPm6nvPf4Ny3DvRsY1fAChJJKj-fGXRFUicJYxUU" })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          console.log(localStorage.getItem('token'));
          // Perform any other necessary action with the token

          // Make API call to process.env.REACT_APP_DEV_API_URL + auth/firebase-token
          const jwtToken = localStorage.getItem('token');
          const apiUrl = `${url}auth/firebase-token`;
          axios.post(apiUrl, { "firebase_token" : currentToken }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtToken}`
            }
          })
            .then(response => {
              // Handle the API response
              console.log(response.data);
            })
            .catch(error => {
              console.log('An error occurred while making the API call. ', error);
            });
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });