import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkoutForm"; // Assuming CheckoutForm is the correct filename
import "./style.scss";

const stripePromise = loadStripe("pk_test_51OD0DrDrq7272YHRdIdWlOnapV4VeTlcZ1ojzteTjCQeqE4jJu6nBipBFALOhXotDb7GeQHeRbmb3Drzv2M4tsdw00yyZv111l");

const BuyProductFromLink = () => {
  const { productLink } = useParams();
  const [product, setProduct] = useState(null);
  const [options, setClientSecret] = useState();
  const history = useHistory();

  async function fetchPaymentIntent() {
    const token = localStorage.getItem('token');

    if (!token || !productLink) {
      console.log('JWT token not found or invalid product ID.');
      return;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token || !productLink) {
          console.log('JWT token not found or invalid product ID.');
          return;
        }
        const clientSecretParam = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
        const userResponse = await axios.get(`${process.env.REACT_APP_DEV_API_URL}/auth/getUser`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(userResponse.data.data);
        // setProduct(productResponse.data.data);
        window.location.replace(`https://sales.aumvers.com/?rest_route=/simple-jwt-login/v1/autologin&JWT=${userResponse.data?.data?.jwt}&redirectUrl=https://sales.aumvers.com/product/${productLink}`)
        // Create a Stripe session
        if (clientSecretParam) {
          setClientSecret({
            clientSecret: clientSecretParam,
            appearance: {
              theme: 'stripe',
            }
          });
        }
      } catch (error) {
        console.error('Error fetching product or creating Stripe session:', error);
      }
    };

    fetchData();
  }, [productLink]);

  return (
    <Container fluid className="dashboard-layout">
      <Suspense fallback={<div>Loading...</div>}>
        {product && (
          <Row key={product.id}>
            
              <h2>{product.productName}</h2>
          </Row>
        )}
      </Suspense>
    </Container>
  );
};

export default BuyProductFromLink;
