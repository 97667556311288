import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Iframe from 'react-iframe';

const Shop = () => {
  const { productId } = useParams();
  const [url, setUrl] = useState();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          console.log('JWT token not found ');
          return;
        }
        const userResponse = await axios.get(`${process.env.REACT_APP_DEV_API_URL}/auth/getUser`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(userResponse.data.data);
        setUrl(`https://sales.aumvers.com/?rest_route=/simple-jwt-login/v1/autologin&JWT=${userResponse.data?.data?.jwt}&redirectUrl=https://sales.aumvers.com/shopping-cart/`);
        window.location.replace(`https://sales.aumvers.com/?rest_route=/simple-jwt-login/v1/autologin&JWT=${userResponse.data?.data?.jwt}&redirectUrl=https://sales.aumvers.com/store/aumvers-premium/`)
      } catch (error) {
        console.error('Error fetching product or creating Stripe session:', error);
      }
    };

    fetchData();
  }, []);

  const handleGoBack = () => {
    window.receiveMessage("back");
    // Or redirect using history.push('/path') if it's appropriate
  };

  return (
    <div>
      
    </div>
  );
};

export default Shop;
