export const urls = {
    'LOGIN_URL'   : '/auth/login',
    'CONFIRM_URL' : '/auth/confirm',
    'VISITOR_URL' :  '/visitor', 
    'DASHBOARD'   :  '/avatar',
    'APP'        :  '/app',
    'REGISTER' : '/auth/register',
    'BUYPRODUCT' : '/buyproduct',
    'BUYPRODUCTWP' : '/buyproductWP',
    'PRODUCTS' : '/products',
    'ORDERS' : '/orders',
    'NECKLACE1' : '/necklace1',
    'NECKLACE2' : '/necklace2',
    'cart' : '/cart',
    'FORGOTPASSWORD' : '/auth/forgot-password',
} 